import React, { useState } from "react";
import { getFixedGatsbyImage } from "gatsby-storyblok-image";
import { Box, Image } from "theme-ui";
import ReactMarkdown from "react-markdown";
import SbEditable from "storyblok-react";
import PropTypes from "prop-types";

const Work = ({ blok }) => {
	const [isOpen, setOpen] = useState(false);
	const { image, content } = blok || {};

	if (!blok.image.filename) {
		return null;
	}

	const noProtocolUrl = image.filename
		? image.filename.replace(/(^\w+:|^)\/\//, "//")
		: "";

	const thumbnailProps = getFixedGatsbyImage(noProtocolUrl, {
		format: "jpeg",
		width: 600,
	});
	const largeProps = getFixedGatsbyImage(noProtocolUrl, {
		format: "jpeg",
		width: 1200,
	});

	return (
		<SbEditable content={blok}>
			<Box
				as="figure"
				sx={{
					mb: [5, 5, 6],
				}}
			>
				<Box
					sx={{
						height: ["auto", isOpen ? "auto" : "100%"],
						width: ["100%", isOpen ? "100%" : "auto"],
						textAlign: "center",
					}}
				>
					<Image
						{...thumbnailProps}
						onClick={() => setOpen(!isOpen)}
						sx={{
							maxHeight: ["90%", "30rem"],
							maxWidth: ["90%", "30rem"],
							cursor: ["pointer", "zoom-in"],
							height: "auto",
							width: "auto",
							display: ["inline-block", isOpen ? "none" : "inline-block"],
						}}
					/>
					<Image
						{...largeProps}
						onClick={() => setOpen(!isOpen)}
						sx={{
							maxHeight: ["90%", "60rem"],
							maxWidth: ["90%", "60rem"],
							cursor: ["pointer", "zoom-out"],
							height: "auto",
							width: "auto",
							display: ["none", isOpen ? "inline-block" : "none"],
						}}
					/>
					{isOpen && content && (
						<figcaption>
							<ReactMarkdown source={content} />
						</figcaption>
					)}
				</Box>
			</Box>
		</SbEditable>
	);
};

Work.defaultProps = {
	blok: null,
};

Work.propTypes = {
	blok: PropTypes.shape({
		title: PropTypes.string,
		image: PropTypes.shape({
			filename: PropTypes.string,
		}),
	}),
};

export default Work;
