import React, { useState, useEffect } from "react";
import { Element, scroller } from "react-scroll";
import { Box, Button, Heading } from "theme-ui";
import SbEditable from "storyblok-react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Work from "./Work";
import useHashParam from "./useHash";

const Show = ({ blok, slug }) => {
	const [show, setShow] = useHashParam("show");

	const [isOpen, setOpen] = useState(false);
	const { title, main_content, works = [] } = blok || {};
	const openBoxProps = {
		pb: 4,
		mb: 4,
	};

	const handleClick = () => {
		if (isOpen) {
			setShow("");
			return;
		}
		setShow(slug);
	};

	useEffect(() => {
		if (show === slug) {
			setOpen(true);
			scroller.scrollTo(slug, { smooth: true, offset: -15, duration: 500 });
		} else {
			setOpen(false);
		}
	}, [show, slug]);

	if (!blok) {
		return null;
	}

	return (
		<SbEditable content={blok}>
			<Element name={slug}>
				<Box sx={isOpen && openBoxProps} mb={4}>
					<Box sx={{ width: "100%" }} mb={3}>
						<Button
							type="button"
							onClick={handleClick}
							sx={{
								textAlign: "left",
								cursor: "pointer",
								borderRadius: 0,
								backgroundColor: "transparent",
								appearance: "none",
								border: "none",
								padding: "1px 0",
								color: (theme) =>
									isOpen ? theme.colors.primary : theme.colors.text,
								":focus": {
									outline: "none",
									paddingBottom: 0,
									borderBottom: (theme) => `1px solid ${theme.colors.muted}`,
								},
							}}
						>
							<Heading variant="headingMedium" color="inherit">
								{title}
							</Heading>
						</Button>
					</Box>
					<Box sx={{ width: "100%", display: isOpen ? "block" : "none" }}>
						<Box sx={{ mb: 4 }}>
							{main_content && <ReactMarkdown source={main_content} />}
						</Box>
						<Box>
							{works.map((work) => {
								const { _uid: key } = work;
								return <Work key={key} blok={work} />;
							})}
						</Box>
					</Box>
				</Box>
			</Element>
		</SbEditable>
	);
};

Show.defaultProps = {
	blok: null,
	slug: "",
};

Show.propTypes = {
	blok: PropTypes.shape({
		title: PropTypes.string,
	}),
	slug: PropTypes.string,
};

export default Show;
